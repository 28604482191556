import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgMarketRecover from "../images/market-recover.jpg"
import imgDirtyMoney from "../images/dirty-money.jpg"
import imgPassengersMask from "../images/passengers-in-masks.jpg"
import imgElearning from "../images/elearning.jpg"
import imgDriverMask from "../images/coach-driver-mask.jpg"
import imgSocialDistancing from "../images/social-distancing.png"
import imgGrowth from "../images/growth.jpg"
import imgEfficient from "../images/efficient.png"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="7 ways COVID-19 could affect home to school transport" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">7 ways COVID-19 could affect home to school transport</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">7 ways COVID-19 could affect home to school transport</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <img src={imgMarketRecover} alt="" />
                    <p>Many bus and coach companies will be thinking about how to survive the next few weeks but inevitably the industry will bounce back. When it does, we need to be prepared for reality in a post-pandemic environment.</p>
                    <p>We've included industry opinion and shortlisted seven issues that have the potential to impact home to school transport in the long term.</p>
                  </div>
                  <div className='article-inner'>

                    <h2>1. Say goodbye to physical cash</h2>
                    <p><img src={imgDirtyMoney} alt="" /></p>
                    <p>Physical cash is being used less and less and in the aftermath of Coronavirus, for hygiene and safety reasons we anticipate parents will demand to be able to pay by other means. We've already seen operators detering customers from using physical cash in the current climate on public transit.</p>
                    <p>Previously we've heard an argument that taking cash on the buses gives ultimate flexibility. In this new cashless age, flexibility can still be achieved with prepaid digital tickets/passes but without the hygiene and safety concerns for passengers or staff. This has to be the way forward.</p>

                    <h2>2. Reduced capacity for social distancing</h2>
                    <p><img src={imgSocialDistancing} alt="" /></p>
                    <p>During lockdown and the moments running up to it, we've seen <a className="link" href="https://twitter.com/McCollsCoaches/status/1245442004613582855">social distancing used even on buses and coaches</a>. However, this can't continue longer term, as we all know that private home to school transport is a numbers game. To be viable, enough passengers need to be travelling.</p>
                    <p>Compensating the lack of social distancing, we foresee increased mask usage by passengers. We only need to look at Asia in the wake of SARS; commercial transport recovered but the adoption of mask wearing in public settings significantly increased.</p>
                    <p>Whilst currently the World Health Organisation advises against mask wearing for the general public, this has not stopped governments in Austria, Czech Republic, France, Israel and now the US recently mandating mask usage. In fact, the <a className="link" href="https://www.bbc.co.uk/news/science-environment-52126735">stance is now being questioned</a> by a panel of experts and is set to be reviewed frequently. If the advice does change, expect DIY masks to become commonplace.</p>

                    <h2>3. The end of the PVC Photo ID</h2>
                    <p><img src={imgPassengersMask} alt="" /></p>
                    <p>One of the main issues we have with the traditional PVC photo ID, is that all too often, they just don't get checked. Passengers flash them in the blink of an eye and drivers simply don't get the time to check the full validity.</p>
                    <p>Now add facemasks into the equation and the PVC Photo ID becomes even less effective. It will become even harder to prevent ticket fraud and ensure the safety of paying passengers.</p>
                    <p>To counteract this, operators may instead want to look into forms of contactless tickets/passes that can be scanned, making photo IDs redundant. There are plenty of options on the market and they don't necessarily need to cost the earth.</p>
                    <p>Tom Murphy, <a className="link" href="http://www.ashwoodtravel.co.uk/">Ashwood Travel</a> Managing Director, shared his opinion: "Using photo IDs will no longer be a practical solution if students are wearing masks. While the mask is worn the driver will be unable to verify the identity and if the student removes the mask they will need to touch their face with their hands which is absolutely the wrong thing to do in a virus prevention scenario."</p>
                    <p>"Contactless passes presented to a scanner on the bus offer the quickest and safest method to ensure students have the correct entitlement to travel which is why we have chosen to partner with ShuttleID."</p>

                    <h2>4. PPE and cleaning costs to rise</h2>
                    <p><img src={imgDriverMask} alt="" /></p>
                    <p>Whilst most operators running home to school services already have good policies in place it's understandable that parents will be placing this under scrutiny going forward. In future expect these efforts to ramp up a notch, with cleaning done even more frequently and possibly to a higher standard (think deep cleaning and fogging). With <a className="link" href="https://www.adzuna.co.uk/blog/covid-19-hits-the-job-market-vacancies-drop-22-in-six-weeks">demand also increasing for cleaners</a>, there's a real possibility that the job of cleaning will become more expensive in itself.</p>
                    <p>It goes without saying that efforts to protect drivers and other staff will be need to be made, increasing spend for PPE. With the <a className="link" href="https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/852652/annual-bus-statistics-2019.pdf">2019 Labour Force Survey</a> reporting that 60+ being the most common age group for bus and coach drivers, it is a concern given they pose a higher risk from the virus outbreak. Employers will need to step up and ensure their working environment is as safe as possible.</p>

                    <h2>5. eLearning risk to passenger numbers</h2>
                    <p><img src={imgElearning} alt="" /></p>
                    <p>Much like the sudden shift to remote working, distance learning or eLearning for students is undergoing a trial by fire. Whilst distance learning certainly has a role to play, we just don't think it will suddenly stop kids physically attending schools.</p>
                    <p>Why? Well, we feel a big part of the reasoning is childcare, which has been pretty evident to a lot of parents during lockdown. We don't anticipate that many businesses will continue their 100% remote working policy, which means parents will continue to be dependent on schools to provide a form of childcare.</p>
                    <p>In fact, teachers aren't the only ones child minding. One operator we spoke with is convinced parents use their service as a before/after school kids club.</p>
                    <p>"We know they do it. We've seen kids who live a five minute walk from school, get our service from a different stop which would keep them on the bus for an extra hour. They're not daft, it's cheaper for them to do it this way." Said one coach company director who wishes to remain anonymous.</p>

                    <h2>6. Increased patronage</h2>
                    <p><img src={imgGrowth} alt="" /></p>
                    <p>As heartbreaking as it is, the reality is one in five small to medium sized <a className="link" href="https://www.bbc.co.uk/news/business-52114414">businesses are expected to go out of business</a> in the next four weeks. We can only hope that more measures are put in place by the Government to protect the coach industry, which provide vital services for communities.</p>
                    <p>However, this may leave some operators suddenly finding the weight of responsibility in providing brand new services to safeguard the continuation of travel for school children, doubling their passenger count overnight. A lot of operators are thinking about the consequences of scaling down, but it could be worth also giving some consideration to what would be needed to scale up and fast. Automation is likely to play a key role in your ability to cope with sudden spikes in demand.</p>

                    <h2>7. Working smarter</h2>
                    <p><img src={imgEfficient} alt="" /></p>
                    <p>With the temporary downtime, it would be a wasted opportunity to not examine ways in which you could be working smarter, allowing you to do more with less.</p>
                    <p>When some normality resumes, the work, and associated admin and payments are likely to flood in all at once, leaving staff with a difficult job. Add to this the risk of staff absences due to self-isolation periods and you may find yourself with a stressed workforce and frustrated customers.</p>
                    <p>Here are just a few ways you can work smarter:</p>
                    <ul style={{ width: 'auto' }}>
                      <li>Make your school bus tickets/passes digital to avoid the admin of data collection, pass production and handling lost pass requests</li>
                      <li>Take the admin out of scheduled payments by using a system to notify you on failure and re-attempt collection automatically</li>
                      <li>Leverage modern systems such as vehicle management systems and daily walkaround apps</li>
                      <li>Allow invoices to be paid online to avoid a queue for the card machine and clogging up staff with slow and expensive phone calls</li>
                      <li>Improve your website with information and functionality for common questions and tasks that can instead become "self service"</li>
                      <li>Document processes internally to avoid a team member becoming a single point of failure</li>
                    </ul>
                    <p>Anthony Marett, MD of <a className="link" href="https://www.donttravelempty.com/">Don't Travel Empty</a>, said: "Data is the new currency. Business's who use accurate data are three times more likely to make good decisions than those who don't. DTE is all about efficiencies, whether making more money per job or spending less money the aim is to make the gap between takings and spend as big as possible. Using DTE and the data we collect 30% net profit is achievable."</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, a cost effective cashless ticketing system designed to make managing home to school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 14 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing home to school first hand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage